import React  from 'react';
import './App.css';

import { Link, Route, Routes } from 'react-router-dom';

import ApplicationForm from './ApplicationForm';
import AddNewJobComponent from './AddNewJobComponent';
import { ApplyToJobPage, JobComponent, JobList } from './JobComponents';


const HomePage = () => {
  return (
    <div className="text-center mt-3 mt-xl-2">
      <div className='row mb-5'>
        <div className='col-12 col-md-10 col-lg-7 col-xxl-6 mx-auto px-2'>
          <img src="home.jpeg" alt="Home Page" className="img-fluid rounded" />
        </div>
        <div className='col-12 col-md-9 col-lg-5 col-xxl-6 mx-auto d-flex align-items-center pb-2'>
          <div className='px-2'>
          <h1 className='my-5'>{process.env.REACT_APP_AGENCY_NAME}</h1>
          <h4>Empowering Professionals, Shaping Futures</h4>
          <p>Join our staffing agency to explore exciting employment opportunities in the US. We connect qualified professionals with leading recruitment agencies, addressing the high demand for employees. Apply now to start your journey!</p>
          <a href='#jobs' className='btn btn-lg btn-dark mt-4'>Browse Jobs</a>
          {/* <Link to='/apply' className='btn btn-lg btn-dark mt-4'>Explore Opportunities</Link> */}
        </div>
        </div>
      </div>
      <div id='jobs'><JobList/></div>
    </div>
  );
};

const ContactPage = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <h1 className="card-title text-center">Contact Us</h1>
              <p className="card-text text-center mt-4">
                The best way to get in touch with {process.env.REACT_APP_AGENCY_NAME} is to send an email to:
              </p>
              <p className="card-text text-center">
                <a href={`mailto:${process.env.REACT_APP_AGENCY_CONTACT}`}>{process.env.REACT_APP_AGENCY_CONTACT}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SubmittedSuccessfullyPage = () => {
  return (
    <div className="text-center mt-5 mt-xl-2">
      <div className='row'>
        <div className='col col-md-8 col-lg-6 col-xxl-4 mx-auto'>
          <h1 className='my-5'>Submitted Successfully</h1>
          <h4>{`Thank you for applying to ${process.env.REACT_APP_AGENCY_NAME} .`}</h4>
          <p className='mt-3'>We will review your application and get back to you soon.</p>
          <p className='mt-5 fw-bold'>We will only contact you from our official email address: <a href={`mailto:${process.env.REACT_APP_AGENCY_CONTACT}`}>{process.env.REACT_APP_AGENCY_CONTACT}</a></p>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <header>
        <nav className='navbar navbar-expand-md'>
          <div className='container-fluid'>
            <Link to='/' className='navbar-brand'>{process.env.REACT_APP_AGENCY_NAME}</Link>
            <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
              <div className='navbar-nav'>
                <Link to='/contact' className='nav-link'>Contact</Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className='container'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/apply" element={<ApplicationForm />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/jobs/:id" element={<JobComponent />} />
          <Route path="/jobs/apply/:id" element={<ApplyToJobPage />} />
          <Route path="/newj" element={<AddNewJobComponent/>} />
          <Route path="/success" element={<SubmittedSuccessfullyPage />} />
        </Routes>
      </div>
      <footer className='footer'>
        <div className='container'>
          {process.env.REACT_APP_AGENCY_NAME} &copy; {new Date().getFullYear()}
        </div>
      </footer>
    </div>
  );
}

export default App;
