import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const JsonComponentView = ({ data }: any) => {
    if (data === undefined || data === null) {
        return null;
    } else if (typeof(data) === 'string' || typeof(data) === 'number') {
        return <p className='m-0 p-0 text-capitalize'>{data}</p>;
    } else if (typeof(data) === 'boolean') {
        if (data) {
            return <p className='m-0 p-0'>Yes</p>;
        }
        return <p className='m-0 p-0'>No</p>;
    } else if (data instanceof Array) {
        if (data.length === 0)
            return null;
        else if (data.length === 1)
            return <p className='m-0 p-0 text-capitalize'>{data[0]}</p>;
        return (
            <ul className='list-group'>
                {data.map((item: any, index: number) => (
                    <JsonComponentView data={item} key={index} />
                ))}
            </ul>
        );
    }

    return (
        <div>
            {Object.keys(data).map((key: any, index: number) => {
                if (data[key] === undefined || data[key] === null) {
                    return null;
                }
                return (
                    <div>
                        <p className='m-0 p-0 text-capitalize'><strong>{key}</strong></p>
                        <div className='ps-3'>
                            <JsonComponentView data={data[key]} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const JobComponent = () => {
    const { id } = useParams();
    const [job, setJob]: any = useState({});

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/jobs/${id}`)
        .then((response) => response.json())
        .then((data) => setJob(data));
    }, [id]);

    return (
        <div className='container'>
            <h1 className='mt-5'>{job.title}</h1>
            <div>
                {job['overview'] &&
                <div className='text-start'>
                    <h4 className='mb-lg-3'>Overview</h4>
                    <JsonComponentView data={job['overview']} />
                </div>}
                {job['responsibilities'] &&
                <div className='pt-3 text-start'>
                    <h4 className='mb-lg-3'>Responsibilities</h4>
                    <JsonComponentView data={job['responsibilities']} />
                </div>}
                {job['additional_details'] &&
                <div className='pt-3 text-start'>
                    <h4>Additional Information</h4>
                    <JsonComponentView data={job['additional_details']} />
                </div>}
            </div>
            <a href={`apply/${id}`} className='btn btn-dark mt-4'>Apply Now</a>
      </div>
    );
}

const JobCard = ({ job }: any) => {
    if ((job.summary[0] === "'" && job.summary[job.summary.length - 1] === "'") ||
        (job.summary[0] === '"' && job.summary[job.summary.length - 1] === '"')) {
        job.summary = job.summary.substring(1, job.summary.length - 1);
    }
    return (
        <div className='card my-3 h-100'>
            <div className='card-header fs-5 fw-bold'>{job.title}</div>
            <div className='card-body'>
                <p className='card-text' style={{fontSize: '.9em'}}>{job.summary}</p>
                <a className="btn btn-dark"
                href={`/jobs/${job.id}`}>View</a>
            </div>
        </div>
    );
}

const JobList = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/jobs`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to fetch jobs');
            }
            return response.json()
        })
        .then((data) => setJobs(data));
    }, []);

    return (
        <div className='mx-auto row'>
            <div>
              <h2>Current Jobs</h2>
              <div className='row'>
                {(jobs && jobs.length !== 0) &&
                jobs.map((job: any, index: number) => (
                  <div className='col-md-6 col-lg-4 py-2' key={index}>
                    <JobCard key={job.id} job={job} />
                  </div>
                ))}
                <div className='col-md-6 col-lg-4 py-2'>
                  <div className='card my-3 h-100'>
                    <div className='card-header fs-5 fw-bold'>Teacher</div>
                    <div className='card-body'>
                      <p className='card-text' style={{fontSize: '.9em'}}>General Application to Teach In the United States</p>
                      <a className="btn btn-dark" href='/apply'>Apply</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
}

const validatePersonalInfo = (formData: any) => {
    if (!formData.name.trim()) {
        return "Full Name is required.";
    }

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailPattern.test(formData.email)) {
        return "Invalid email address.";
    }

    if (!formData.countryCode) {
          return "Country Code is required.";
    }

    const phoneNumberPattern = /^[0-9]{9,10}$/;
    if (!formData.phoneNumber) {
        return "Phone Number is required.";
    } else if (!phoneNumberPattern.test(formData.phoneNumber)) {
        return "Invalid phone number format.";
    }

    if (!formData.country) {
        return "Country of Origin is required.";
    }

    if (!formData.education) {
        return "Highest Level of Education is required.";
    }

    return null;
}

const validateFiles = (files: any, requiredFiles: any) => {
    for (const file of requiredFiles) {
        if (!files[file]) {
          return "All required files are not uploaded.";
        }
    }
    return null;
}

/**
 * split a string from the last occurrence of a separator
 */
const reverseSplit = (str: string, separator: string) => {
    const index = str.lastIndexOf(separator);
    if (index === -1) {
        return [str];
    }
    return [str.substring(0, index), str.substring(index + 1)];
}

const getAcceptedFileTypes = (fileType: string) => {
    switch (fileType) {
        case 'document':
            return ".pdf,.doc,.docx,.txt,image/*";
        case 'video':
            return 'video/*';
        default:
            return '';
    }
}

const ApplyToJobPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [requiredFiles, setRequiredFiles] = useState([]);
    const [uploadRequired, setUploadRequired] = useState(false);
    const [uploadedFiles, setUploadedFiles]: any = useState({});
    const [formData, setFormData]: any = useState({
      name: '',
      email: '',
      countryCode: '',
      phoneNumber: '',
      country: '',
      education: '',
  });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/jobs/${id}/files`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to fetch required files');
            }
            return response.json()
        })
        .then((data) => {
          setRequiredFiles(data);
          let files: any = {};
          for (const file of data) {
            files[file] = null;
          };
          setUploadedFiles(files);
        }).catch(_ => {});

        return () => {
            setRequiredFiles([]);
            setUploadRequired(false);
            setUploadedFiles({});
        }
    }, [id]);

    const handleChange = (e: any) => {
        const { name, value, files } = e.target;
        if (files) {
          setUploadedFiles({ ...uploadedFiles, [name]: files[0] });
        } else {
          // if value is longer than 1000 characters, do not update state
          if (value.length > 1000) {
              return;
          }
          setFormData({ ...formData, [name]: value });
        }
    };

    const submitApplication = (e: any) => {
        e.preventDefault();
        if (!id) {
            alert('Invalid job application');
            return;
        }
        let personalInfoError = validatePersonalInfo(formData);
        if (personalInfoError) {
            alert(personalInfoError);
            return;
        }
        let filesError = validateFiles(uploadedFiles, requiredFiles);
        if (filesError) {
            alert(filesError);
            return;
        }

        const form = new FormData();
        form.append('job_id', id);
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.countryCode + '-' + formData.phoneNumber);
        form.append('country', formData.country);
        form.append('education', formData.education);

        for (const file in uploadedFiles) {
            form.append(file, uploadedFiles[file]);
        }

        fetch(`${process.env.REACT_APP_API_URL}/jobs/apply/${id}`, {
            method: 'POST',
            body: form,
        }).then(response => {
            if (!response.ok) {
                throw new Error('Failed to submit application');
            }
            return response.json();
        }).then(_ => {
            navigate('/success');
        }).catch(_ => {
            alert('Failed to submit application');
        });
    }

    return (
        <div className='container-lg col-lg-8 col-xl-6'>
            <form>
                <div>
                    <div className="mb-3">
                        <label className='form-label fw-medium' htmlFor="name">Full Name</label>
                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label className='form-label fw-medium' htmlFor="email">Email</label>
                        <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange}/>
                    </div>
                    <div className="row mb-3">
                        <label className="form-label fw-medium" htmlFor="phone">Phone Number</label>
                        <div className="col-4">
                            <select
                            className="form-control"
                            id="countryCode"
                            name="countryCode"
                            value={formData.countryCode}
                            onChange={(e: any) => setFormData({ ...formData, countryCode: e.target.value })}
                            >
                            <option value="">Select Country</option>
                            <option value="+254">Kenya (+254)</option>
                            <option value="+256">Uganda (+256)</option>
                            <option value="+255">Tanzania (+255)</option>
                            </select>
                        </div>
                        <div className="col-8">
                            <input
                            type="tel"
                            className="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={(e: any) => {
                                const formattedValue = e.target.value.replace(/\D/g, '');
                                setFormData({ ...formData, phoneNumber: formattedValue });
                            }}
                            maxLength={10}
                            placeholder="Enter phone number"
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-medium" htmlFor="country" >Country of Origin</label>
                        <select className="form-select" id="country" aria-label="Select country"
                            value={formData.country} onChange={(e) => setFormData({ ...formData, country: e.target.value })}>
                            <option value="">Select country</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Tanzania">Tanzania</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-medium" htmlFor="education">Highest Level of Education</label>
                        <select className="form-select" id="education" aria-label="Select education"
                            value={formData.education} onChange={(e) => setFormData({ ...formData, education: e.target.value })}>
                            <option value="">Select education</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Degree">Degree</option>
                            <option value="Masters">Masters</option>
                            <option value="PhD">PhD</option>
                        </select>
                    </div>
                </div>
                <div className='mt-xl-5 pt-xl-3'>
                    {uploadRequired && <h5 className='text-center mt-3 mb-3'>Upload Files</h5>}
                    {requiredFiles.map((file: any, index: number) => {
                        let parts = reverseSplit(file, '.');
                        if (parts.length < 2) {
                            return null;
                        } else if (parts[1] !== 'document' && parts[1] !== 'video') {
                            return null;
                        }
                        if (!uploadRequired) { setUploadRequired(true); }
                        return (
                        <div className="mb-3" key={index}>
                            <label className='form-label fw-medium text-capitalize' htmlFor={file}>{parts[0]}</label>
                            <input type="file" name={file} className="form-control"
                            accept={getAcceptedFileTypes(parts[1])}
                            onChange={(e: any) => {handleChange(e);}}/>
                        </div>
                    )})}
                </div>
            </form>
            <div className='text-center'>
                <button type="submit" className='btn btn-dark' onClick={submitApplication}>Submit</button>
            </div>

    </div>);
}

export { ApplyToJobPage, JobComponent, JobList };
