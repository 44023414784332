import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from "react-google-recaptcha";


const RESUME_FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
const VIDEO_FILE_SIZE_LIMIT = 50 * 1024 * 1024; // 50MB

const ApplicationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData]: any = useState({
    name: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    phone: '',
    country: '',
    education: '',
    employed: false,

    interests: '',
    extraCurricular: '',
    reasonForTeaching: '',
    hoursPerDay: '',
    daysPerWeek: '',
    teachingExperience: '',
    otherWorkExperience: '',

    workedInUS: '',
    futurePlans: '',
    colleaguesDescription: '',
    studentsDescription: '',
    lessonPreparation: '',
    lessonPlans: '',
    teachingStrategies: '',
    studentProgress: '',

    technologyUsage: '',
    quietStudents: '',
    difficultProblem: '',
    flexiblePerson: '',
    qualifications: '',

    familyApproval: '',
    programDuration: '',

    resume: null,
    video: null,
  });

  const [currFormIndex, setCurrFormIndex] = useState(0);

  const validateFormData = (formData: any) => {
    const errors: any = {};

    if (!formData.name.trim()) {
      errors.name = "Full Name is required.";
    }

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email address.";
    }

    if (!formData.countryCode) {
        errors.countryCode = "Country Code is required.";
    }

    const phoneNumberPattern = /^[0-9]{9,10}$/;
    if (!formData.phoneNumber) {
        errors.phoneNumber = "Phone Number is required.";
    } else if (!phoneNumberPattern.test(formData.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number format.";
    }

    if (!formData.country) {
      errors.country = "Country of Origin is required.";
    }

    if (!formData.education) {
      errors.education = "Highest Level of Education is required.";
    }

    if (formData.employed === undefined) {
      errors.employed = "Employment status is required.";
    }

    const validateText = (field: any, maxLength: number) => {
      if (!formData[field].trim()) {
        errors[field] = `${field} is required.`;
      } else if (formData[field].length > maxLength) {
        errors[field] = `${field} exceeds maximum length of ${maxLength} characters.`;
      }
    };

    validateText("interests", 1000);
    validateText("extraCurricular", 1000);
    validateText("reasonForTeaching", 1000);
    validateText("teachingExperience", 1000);
    validateText("otherWorkExperience", 1000);
    validateText("workedInUS", 1000);
    validateText("futurePlans", 1000);
    validateText("colleaguesDescription", 1000);
    validateText("studentsDescription", 1000);
    validateText("lessonPreparation", 1000);
    validateText("lessonPlans", 1000);
    validateText("teachingStrategies", 1000);
    validateText("studentProgress", 1000);
    validateText("technologyUsage", 1000);
    validateText("quietStudents", 1000);
    validateText("difficultProblem", 1000);
    validateText("flexiblePerson", 1000);
    validateText("qualifications", 1000);
    validateText("familyApproval", 1000);

    if (!formData.programDuration) {
      errors.programDuration = "Program Duration Agreement is required.";
    }

    if (!formData.resume) {
      errors.resume = "Resume file is required.";
    } else if (formData.resume.size > RESUME_FILE_SIZE_LIMIT) {
      errors.resume = "Resume file size exceeds 5MB.";
    }

    if (!formData.video) {
      errors.video = "Introductory video is required.";
    } else if (formData.video.size > VIDEO_FILE_SIZE_LIMIT) {
      errors.video = "Introductory video size exceeds 50MB.";
    }

    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      // if value is longer than 1000 characters, do not update state
      if (value.length > 1000) {
          return;
      }
      setFormData({ ...formData, [name]: value });
    }
  };

  const getInputFieldMaxCharsLeftLabel = (e: any) => {
    if (e) {
      return 1000 - e.length + ' characters left';
    }
    return 'Maximum 1000 characters';
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const errors = validateFormData(formData);
    if (Object.keys(errors).length > 0) {
      let errorStr = '';
      for (const key in errors) {
        errorStr += `${key}: ${errors[key]}\n`;
      }
      alert(`Form Errors:\n ${errorStr}`);
      return;
    }


    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone', formData.countryCode + '-' + formData.phoneNumber);
    data.append('country', formData.country);
    data.append('education', formData.education);
    data.append('employed', formData.employed);

    data.append('interests', formData.interests);
    data.append('extraCurricular', formData.extraCurricular);
    data.append('reasonForTeaching', formData.reasonForTeaching);
    data.append('hoursPerDay', formData.hoursPerDay);
    data.append('daysPerWeek', formData.daysPerWeek);
    data.append('teachingExperience', formData.teachingExperience);
    data.append('otherWorkExperience', formData.otherWorkExperience);

    data.append('workedInUS', formData.workedInUS);
    data.append('futurePlans', formData.futurePlans);
    data.append('colleaguesDescription', formData.colleaguesDescription);
    data.append('studentsDescription', formData.studentsDescription);
    data.append('lessonPreparation', formData.lessonPreparation);
    data.append('lessonPlans', formData.lessonPlans);
    data.append('teachingStrategies', formData.teachingStrategies);
    data.append('studentProgress', formData.studentProgress);

    data.append('technologyUsage', formData.technologyUsage);
    data.append('quietStudents', formData.quietStudents);
    data.append('difficultProblem', formData.difficultProblem);
    data.append('flexiblePerson', formData.flexiblePerson);
    data.append('qualifications', formData.qualifications);

    data.append('familyApproval', formData.familyApproval);
    data.append('programDuration', formData.programDuration);

    data.append('resume', formData.resume);
    data.append('video', formData.video);

    fetch(`${process.env.REACT_APP_API_URL}/apply`, {
      method: 'POST',
      body: data
    }).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        response.json();
    }).then(_ => {navigate('/success');
    }).catch(_ => {});
  };

  return (
    <div className='col-11 col-md-9 col-lg-6 mx-auto'>
        <form className='mt-4 mb-3' onSubmit={handleSubmit}>
        {currFormIndex === 0 &&
            <div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="name">Full Name</label>
                    <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="email">Email</label>
                    <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange}/>
                </div>
                <div className="row mb-3">
                    <label className="form-label fw-medium" htmlFor="phone">Phone Number</label>
                    <div className="col-3">
                        <select
                        className="form-control"
                        id="countryCode"
                        name="countryCode"
                        value={formData.countryCode}
                        onChange={(e: any) => setFormData({ ...formData, countryCode: e.target.value })}
                        >
                        <option value="">Select Country</option>
                        <option value="+254">Kenya (+254)</option>
                        <option value="+256">Uganda (+256)</option>
                        <option value="+255">Tanzania (+255)</option>
                        </select>
                    </div>
                    <div className="col-9">
                        <input
                        type="tel"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(e: any) => {
                            const formattedValue = e.target.value.replace(/\D/g, '');
                            setFormData({ ...formData, phoneNumber: formattedValue });
                        }}
                        maxLength={10}
                        placeholder="Enter phone number"
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label fw-medium" htmlFor="country" >Country of Origin</label>
                    <select className="form-select" id="country" aria-label="Select country"
                        value={formData.country} onChange={(e) => setFormData({ ...formData, country: e.target.value })}>
                        <option value="">Select country</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Tanzania">Tanzania</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label fw-medium" htmlFor="education">Highest Level of Education</label>
                    <select className="form-select" id="education" aria-label="Select education"
                        value={formData.education} onChange={(e) => setFormData({ ...formData, education: e.target.value })}>
                        <option value="">Select education</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Degree">Degree</option>
                        <option value="Masters">Masters</option>
                        <option value="PhD">PhD</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-check-label fw-medium pe-3" htmlFor="employed">Are you currently employed as a teacher</label>
                    <div className="form-check form-check-inline">
                        <input type="radio" name="employed" className="form-check-input" value="true"
                            checked={formData.employed} onChange={(e) => setFormData({ ...formData, employed: true })}/>
                        <label className="form-check-label" htmlFor="employed">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input type="radio" name="employed" className="form-check-input" value="false"
                            checked={!formData.employed} onChange={(e) => setFormData({ ...formData, employed: false })}/>
                        <label className="form-check-label" htmlFor="employed">No</label>
                    </div>
                </div>
            </div>
        }
        {currFormIndex === 1 &&
            <div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="interests">
                        Please tell us about yourself. What are your interests and hobbies?
                    </label>
                    <textarea name="interests" className="form-control" value={formData.interests} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.interests)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="extraCurricular">
                        Are you involved in extra-curricular activities that you could coach or supervise?
                    </label>
                    <textarea name="extraCurricular" className="form-control" value={formData.extraCurricular} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.extraCurricular)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="reasonForTeaching">
                        Why did you decide to become a teacher for the subject for which you are applying?
                    </label>
                    <textarea name="reasonForTeaching" className="form-control" value={formData.reasonForTeaching} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.reasonForTeaching)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="hoursPerDay">
                        How many hours per day do you teach?
                    </label>
                    <select className="form-select" id="hoursPerDay" aria-label="Select hours per day"
                        value={formData.hoursPerDay} onChange={(e) => setFormData({ ...formData, hoursPerDay: e.target.value })}>
                        <option value="">Select hours per day</option>
                        <option value="1-2">1-2 hours</option>
                        <option value="3-4">3-4 hours</option>
                        <option value="5-6">5-6 hours</option>
                        <option value="7-8">7-8 hours</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="daysPerWeek">
                        How many days per week do you teach?
                    </label>
                    <select className="form-select" id="daysPerWeek" aria-label="Select days per week"
                        value={formData.daysPerWeek} onChange={(e) => setFormData({ ...formData, daysPerWeek: e.target.value })}>
                        <option value="">Select days per week</option>
                        <option value="1-2">1-2 days</option>
                        <option value="3-4">3-4 days</option>
                        <option value="5-6">5-6 days</option>
                        <option value="7">7 days</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="teachingExperience">
                        Describe your teaching experience. What subjects have you taught and at what grade level?
                    </label>
                    <textarea name="teachingExperience" className="form-control" value={formData.teachingExperience} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.teachingExperience)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="otherWorkExperience">
                        Describe other work experiences you have had besides teaching.
                    </label>
                    <textarea name="otherWorkExperience" className="form-control" value={formData.otherWorkExperience} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.otherWorkExperience)}</div>
                </div>
            </div>
        }
        {currFormIndex === 2 &&
            <div className=''>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="workedInUS">
                        Have you ever worked in the United States? If so, when, where and why?
                    </label>
                    <textarea name="workedInUS" className="form-control" value={formData.workedInUS} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.workedInUS)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="futurePlans">
                        What do you hope to be doing professionally 5 years from now?
                    </label>
                    <textarea name="futurePlans" className="form-control" value={formData.futurePlans} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.futurePlans)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="colleaguesDescription">
                        If I were to talk to several of your colleagues, how would they describe your personality?
                    </label>
                    <textarea name="colleaguesDescription" className="form-control" value={formData.colleaguesDescription} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.colleaguesDescription)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="studentsDescription">
                        What would your students say about you?
                    </label>
                    <textarea name="studentsDescription" className="form-control" value={formData.studentsDescription} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.studentsDescription)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="lessonPreparation">
                        How do you prepare to teach your lessons?
                    </label>
                    <textarea name="lessonPreparation" className="form-control" value={formData.lessonPreparation} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.lessonPreparation)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="lessonPlans">
                        What would your lesson plans include?
                    </label>
                    <textarea name="lessonPlans" className="form-control" value={formData.lessonPlans} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.lessonPlans)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="teachingStrategies">
                        Think about a class that you have taught recently. Talk about 2 of the teaching strategies or methods that you used.
                    </label>
                    <textarea name="teachingStrategies" className="form-control" value={formData.teachingStrategies} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.teachingStrategies)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="studentProgress">
                        What methods do you use to grade or evaluate your student’s progress?
                    </label>
                    <textarea name="studentProgress" className="form-control" value={formData.studentProgress} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.studentProgress)}</div>
                </div>
            </div>
        }
        {currFormIndex === 3 &&
            <div className=''>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="technologyUsage">
                        If you use technology in your teaching, describe how you use it. Specifically discuss your experience using a Smartboard and ways that you incorporate it into your lessons.
                    </label>
                    <textarea name="technologyUsage" className="form-control" value={formData.technologyUsage} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.technologyUsage)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="quietStudents">
                        If you have students who are noisy or non-attentive, share with us 2 ways you get them to be quiet and concentrate?
                    </label>
                    <textarea name="quietStudents" className="form-control" value={formData.quietStudents} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.quietStudents)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="difficultProblem">
                        Describe the most difficult problem you have had to deal with as a teacher and how did you solve this problem?
                    </label>
                    <textarea name="difficultProblem" className="form-control" value={formData.difficultProblem} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.difficultProblem)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="flexiblePerson">
                        Give 1 example to show that you are a flexible person and that you can make changes to your teaching and methods if that is necessary.
                    </label>
                    <textarea name="flexiblePerson" className="form-control" value={formData.flexiblePerson} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.flexiblePerson)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="qualifications">
                        Is there anything you would like to add about your qualifications that we may not be aware of?
                    </label>
                    <textarea name="qualifications" className="form-control" value={formData.qualifications} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.qualifications)}</div>
                </div>
            </div>
        }
         {currFormIndex === 4 &&
            <div className=''>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="familyApproval">
                        Have you spoken to your family about going to the United States to teach? Is there anything, such as a family member, that might object to your leaving home? Are you aware that your family members may not join you here until approximately 4 to 6 weeks after your arrival?
                    </label>
                    <textarea name="familyApproval" className="form-control" value={formData.familyApproval} onChange={handleChange}/>
                    <div className="form-text">{getInputFieldMaxCharsLeftLabel(formData.familyApproval)}</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="programDuration">
                        Do you understand and accept that J1 is a 3 year program and after 3 years you must return to your home country? Do you agree to this?
                    </label>
                    <div className="form-check col-6 col-lg-2 mx-auto">
                        <input type="checkbox" name="programDuration" className="form-check-input"
                            checked={formData.programDuration} onChange={(e) => setFormData({ ...formData, programDuration: e.target.checked })}/>
                        <label className="form-check-label" htmlFor="programDuration">I agree</label>
                    </div>
                </div>
            </div>
        }
        {currFormIndex === 5 &&
            <div className=''>
            <h5 className='text-center mt-3'>Upload Files</h5>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="resume">Resume</label>
                    <input type="file" name="resume" className="form-control"
                    accept=".pdf,.doc,.docx,.txt,image/*" onChange={
                        (e: any) => {
                            if (e.target.files[0].size > RESUME_FILE_SIZE_LIMIT) {
                                alert('Resume file size exceeds 5MB');
                            }
                            setFormData({ ...formData, resume: e.target.files[0] });
                        }
                    }/>
                    <div className="form-text">Maximum 5MB</div>
                </div>
                <div className="mb-3">
                    <label className='form-label fw-medium' htmlFor="video">Introductory Video</label>
                    <input type="file" name="video" className="form-control"
                    accept="video/*" onChange={
                        (e: any) => {
                            if (e.target.files[0].size > VIDEO_FILE_SIZE_LIMIT) {
                                alert('Introductory video size exceeds 50MB');
                            }
                            setFormData({ ...formData, video: e.target.files[0] });
                        }
                    }/>
                    <div className="form-text">A short video introducing yourself and your teaching experience</div>
                    <div className="form-text">Maximum 50MB</div>
                </div>
            </div>
        }
        </form>
        <div className='my-5 pb-5'>
            {currFormIndex > 0 && <button className="btn btn-dark me-3" onClick={() => setCurrFormIndex(currFormIndex - 1)}>Back</button>}
            {currFormIndex < 5 && <button className="btn btn-dark" onClick={() => setCurrFormIndex(currFormIndex + 1)}>Next</button>}
        </div>
        {currFormIndex === 5 && <div>
            <div className=''><button type="submit" className="btn btn-dark mt-5" onClick={handleSubmit}>Submit</button></div>
        </div>}
        <div className="">
            {/* <ReCAPTCHA sitekey="ss" /> */}
        </div>
    </div>
    );
};

export default ApplicationForm;
