import React, { useState } from 'react';

const AddNewJobComponent = () => {
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [overview, setOverview] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [files, setFiles] = useState([{ filename: '', filetype: '' }]);

  const handleFileChange = (index: any, field: any, value: any) => {
    const newFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, [field]: value };
      }
      return file;
    });
    setFiles(newFiles);
  };

  const addFileInput = () => {
    setFiles([...files, { filename: '', filetype: '' }]);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (overview.length <= 15000) {
      const data = new FormData();
      data.append('title', title);
      data.append('summary', summary)
      data.append('overview', overview);
      data.append('responsibilities', responsibilities);
      data.append('additionalInformation', additionalInformation);
      files.forEach((file, index) => {
        data.append(`file${index}`, file.filename);
        data.append(`filetype${index}`, file.filetype);
      });

      fetch(`${process.env.REACT_APP_API_URL}/addjob`, {
        method: 'POST',
        body: data,
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else {
          response.json();
        }
      }).then((data) => {
        alert('Job listing created successfully');
      }).catch((_) => {
        alert('An error occurred. Please try again later.');
      });
    } else {
      alert('Form contains errors.');
    }
  };

  return (
    <div className="container mt-4 col-8">
      <h2 className='mb-3'>Add New Job</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label className='form-label'  htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter job title"
          />
        </div>
        <div className="form-group mb-3">
          <label className='form-label'  htmlFor="summary">Summary</label>
          <input
            type="text"
            className="form-control"
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            placeholder="Enter job summary"
          />
        </div>
        <div className="form-group mb-3">
          <label className='form-label'  htmlFor="overview">Overview</label>
          <textarea
            className="form-control"
            id="overview"
            value={overview}
            onChange={(e) => setOverview(e.target.value)}
            placeholder="Enter job overview"
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label className='form-label'  htmlFor="responsibilities">Responsibilities</label>
          <textarea
            className="form-control"
            id="responsibilities"
            value={responsibilities}
            onChange={(e) => setResponsibilities(e.target.value)}
            placeholder="Enter responsibilities"
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label className='form-label'  htmlFor="additionalInformation">Additional Information</label>
          <textarea
            className="form-control"
            id="additionalInformation"
            value={additionalInformation}
            onChange={(e) => setAdditionalInformation(e.target.value)}
            placeholder="Enter additional information"
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label className='form-label' >Files</label>
          {files.map((file, index) => (
            <div key={index} className="row mb-2">
              <input
                type="text"
                className="form-control col me-3"
                placeholder="File Title"
                value={file.filename}
                onChange={(e) => handleFileChange(index, 'filename', e.target.value)}
              />
              <select
                className="form-control col"
                value={file.filetype}
                onChange={(e) => handleFileChange(index, 'filetype', e.target.value)}
              >
                <option value="">Select file type</option>
                <option value="document">Document</option>
                <option value="video">Video</option>
              </select>
            </div>
          ))}
          <button type="button" className="btn btn-dark mb-3" onClick={addFileInput}>
            Add
          </button>
        </div>
        <button type="submit" className="btn btn-dark">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddNewJobComponent;
